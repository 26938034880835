<template>
  <div>
    <GKVAgreementModal ref="GKVAgreementModal" />
    <AGBAgreementModal ref="AGBAgreementModal" />
    <div class="card border-0 shadow">
      <div class="card-body p-5">
        <div class="logo-wrapper">
          <img
            src="@/assets/img/hs_logo_lv.png"
            alt=""
            class="logo mb-4"
          >
        </div>
        <div
          v-if="false"
          class="img-wrapper d-flex justify-content-center mb-5"
        >
          <img
            src="@/assets/img/login-img.svg"
            alt=""
            class="img"
          >
        </div>
        <h3 class="text-center mb-1">
          Anmelden
        </h3>
        <p
          v-if="!loading"
          class="text-center mb-4"
        >
          Mit Ihrem Benutzernamen anmelden
        </p>
        <template v-for="(alert, index) of alerts">
          <ca-alert
            :key="'alert' + index"
            rounded
            :not-closeable="alert.notCloseable"
            :variant="alert.type"
            :message="alert.message"
            :icon="alert.icon"
          />
        </template>
        <template v-if="loading">
          <div class="text-center">
            <i class="fas fa-circle-notch fa-spin fa-3x" />
          </div>
        </template>
        <template v-else>
          <form
            novalidate
            @submit.prevent="login"
          >
            <basic-input
              id="traderId"
              v-model="traderId"
              data-test="traderId"
              :disabled="client.maintenance"
              required
              name="Benutzername"
              label="Benutzername"
              type="text"
            />
            <basic-input
              id="password"
              v-model="password"
              data-test="password"
              :disabled="client.maintenance && !bypassTraderId"
              required
              type="password"
              name="Passwort"
              label="Passwort"
            />
            <button
              :disabled="loginPending"
              type="submit"
              data-test="loginButton"
              class="btn btn-primary text-white w-100 mt-3"
            >
              <span v-if="!loginPending">
                Anmelden
              </span>
              <i
                v-if="loginPending"
                class="fal fa-circle-notch fa-spin"
              />
            </button>

            <router-link
              :to="{name: 'PasswordForgot'}"
              class="d-block mt-3 text-center"
            >
              Passwort vergessen?
            </router-link>
          </form>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import GKVAgreementModal from '@/components/Modals/GKVAgreementModal.vue'
import AGBAgreementModal from '@/components/Modals/AGBAgreementModal.vue'
import agreementType from '@/resources/enums/agreementType.js'
import roles from '@/resources/enums/roles.js'

export default {
  name: 'Login',
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    BasicInput,
    GKVAgreementModal,
    AGBAgreementModal
  },
  data () {
    return {
      traderId: '',
      password: '',
      bypassTraderId: false,
      loading: false,
      loadingError: null,
      loginPending: false,
      loginError: null,
      acceptError: false,
      client: null,
      agreementType
    }
  },
  computed: {
    alerts () {
      const alerts = []
      if (this.client) {
        if (this.client.maintenance) {
          alerts.push({
            type: 'danger',
            notCloseable: true,
            message: this.client.maintenanceText
          })
        }
      }
      if (this.loadingError) {
        alerts.push({
          type: 'danger',
          icon: 'times',
          message: 'Das System ist momentan nicht erreichbar'
        })
      }
      if (this.acceptError) {
        alerts.push({
          type: 'danger',
          icon: 'times',
          message: 'Sie müssen den Änderungen zustimmen, um diese Webseite weiterhin zu nutzen'
        })
      }
      switch (this.$route.query.successType) {
      case 'activateAccount':
        alerts.push(
          {
            type: 'success',
            icon: 'check',
            message: 'Ihr Account wurde aktiviert. Melden Sie sich jetzt mit dem eben vergebenen Passwort an.'
          }
        )
        break
      case 'passwordForgot':
        alerts.push(
          {
            type: 'success',
            icon: 'check',
            message: 'Eine E-Mail zum Zurücksetzem Ihres Passwortes wurde versendet.'
          }
        )
        break
      case 'passwordReset':
        alerts.push(
          {
            type: 'success',
            icon: 'check',
            message: 'Ihr Passwort wurde erfolgreich geändert.'
          }
        )
      }
      if (this.$route.query.tokenExpired) {
        alerts.push(
          {
            type: 'info',
            icon: 'info',
            message: 'Ihre Sitzung ist abgelaufen.'
          }
        )
      }
      if (this.loginError) {
        switch (this.loginError.name) {
        case 'NotAuthenticated': {
          alerts.push({
            type: 'danger',
            icon: 'times',
            message: 'Benutzername oder Passwort falsch'
          })
          break
        }
        case 'Forbidden': {
          alerts.push({
            type: 'danger',
            icon: 'times',
            message: this.loginError.message
          })
          break
        }

        default: {
          alerts.push({
            type: 'danger',
            icon: 'times',
            message: this.loginError.message
          })
        }
        }
      }
      return alerts
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.fetchData()
      }
    }
  },
  async created () {
    // try authentication with jwt token
    try {
      if (this.$route.params.traderId) {
        this.traderId = this.$route.params.traderId
        this.bypassTraderId = this.$route.meta.bypassTraderId
      }
      await this.$store.dispatch('auth/authenticate')
      // const agreementValid = await this.handleAgreement()
      const agreementValid = true

      if (agreementValid) {
        const { redirect, ...query } = this.$route.query
        this.$router.push({ path: redirect || '/', query })
      } else {
        this.acceptError = true
        await this.$store.dispatch('auth/logout')
      }
    } catch (e) {
      if (e.name !== 'NotAuthenticated') {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    }
  },
  methods: {
    async login () {
      try {
        const valid = await this.$validator.validateAll()
        if (!valid) {
          return
        }
        this.loginPending = true
        const authentication = await this.$store.dispatch('auth/authenticate', {
          strategy: 'local',
          entity: 'user',
          username: this.traderId,
          password: this.password
        })

        if (process.env.NODE_ENV === 'production' && authentication.account.roleId !== roles.makler.api) {
          this.$gtm.enable(false)
        }

        // const agreementValid = await this.handleAgreement()
        const agreementValid = true
        if (agreementValid) {
          const { redirect, ...query } = this.$route.query
          if (redirect) this.$router.push({ path: redirect || '/', query })
          else await this.$router.push({ name: 'Home', query })
        } else {
          this.acceptError = true
          await this.$store.dispatch('auth/logout')
        }
      } catch (error) {
        if (!error.message.startsWith('Redirected ')) console.error(error)
        this.loginError = error
      } finally {
        this.loginPending = false
      }
    },
    async fetchData () {
      try {
        this.loading = true
        const clientReq = await this.$store.dispatch('clients/find', {
          query: {
            $limit: 1
          }
        })
        this.client = clientReq.data[0]
      } catch (error) {
        this.loadingError = error
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async handleAgreement () {
      const user = this.$store.getters['auth/user'].user
      const userHasToAgree = []
      let accepted
      if (user.agreements.length > 0) {
        for (const agreement of user.agreements) {
          if (agreement.accepted === false) {
            userHasToAgree.push(agreement)
          }
        }
      }
      if (userHasToAgree.length > 0) {
        for (const agreementToAgree of userHasToAgree) {
          const agreement = await this.$store.dispatch('agreement/get', agreementToAgree.agreementId)
          switch (agreement.agreement) {
          case agreementType.AGB.api:
            accepted = await this.$refs.AGBAgreementModal.open(agreement)
            break
          }
          if (accepted) {
            userHasToAgree[userHasToAgree.findIndex(el => el._id === agreementToAgree._id)].accepted = true
            user.agreements[user.agreements.findIndex(el => el._id === agreementToAgree._id)].accepted = true
            user.save()
          }
        }
        return userHasToAgree.every(el => el.accepted === true)
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="scss">

  @media (min-height: 900px) {
    .img-wrapper{
      .img{
        width: 300px;
      }
    }
  }
  .img-wrapper img, .logo-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo{
    width: 150px;
    margin-bottom: 5px;
  }
</style>
