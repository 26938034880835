<template>
  <Modal
    v-if="show"
    size="lg"
    :title="`Aktualisierte Garantiebedingungen: ${product.name}`"
    :closable="!isDeadline"
    @close="close"
  >
    <template #body>
      <p v-if="product">
        Sehr geehrte Kundschaft, <br>um Ihnen weiterhin den besten Service bieten zu können, haben wir unsere Garantiebedingungen für das folgende Produkt angepasst:
        <ul class="mb-0">
          <li>
            {{ product.name }}
          </li>
        </ul>
        Bitte lesen Sie sich die aktualisierten Garantiebedingungen sorgfältig durch.
      </p>
      <div style="position: relative; overflow-y: hidden">
        <PdfToolbar
          :file="file"
          @zoomIn="pdfScale += 0.25"
          @zoomOut="pdfScale -= 0.25"
        />
        <div
          ref="conditionsBox"
          class="conditions-box mb-3 py-4 overflow-y-scroll"
        >
          <PdfPreview
            v-if="previewData"
            :data="previewData"
            :scale="pdfScale"
          />
        </div>
      </div>
      <BasicCheckbox
        id="accept"
        type="radio"
        data-test="accept-gkv"
        label="Ja, ich akzeptiere die aktualisierten Garantiebedingungen"
        :value="accept"
        @input="accept = true"
      />
      <BasicCheckbox
        id="decline"
        type="radio"
        label="Nein, ich widerspreche den aktualisierten Garantiebedingungen und verlasse die Webseite."
        :value="!accept"
        @input="accept = false"
      />
      <hr>
      <p>
        Sie haben Fragen zu den Änderungen? Sprechen Sie uns einfach an, wir helfen Ihnen gerne weiter.
      </p>
      <div class="d-flex justify-content-between align-items-center">
        <p>
          <b>mobile</b> <i>GARANTIE</i> Deutschland GmbH<br>
          Knibbeshof 10 a<br>
          D-30900 Wedemark<br>
          <a href="mailto:info@mobile-garantie.de">info@mobile-garantie.de</a>
        </p>
        <div class="d-flex align-items-center">
          <p>
            Kontakt Deutschland<br>
            +49 (0) 5130 975 70 30
          </p>
          <p class="ml-4">
            Kontakt Österreich<br>
            +43 (1) 310 15 23
          </p>
        </div>
      </div>
    </template>
    <template #footer>
      <button
        class="btn btn-success text-white float-right"
        data-test="gkv-model-submit"
        @click="submit"
      >
        Weiter
      </button>
    </template>
  </modal>
</template>

<script>
import BasicCheckbox from '../BaseComponents/BasicCheckbox'
import Modal from '@/components/Modal.vue'
import moment from 'moment'
import PdfPreview from '@/components/PdfPreview.vue'
import PdfToolbar from '@/components/PdfToolbar.vue'

export default {
  components: {
    Modal,
    BasicCheckbox,
    PdfPreview,
    PdfToolbar
  },
  data: () => ({
    accept: true,
    acceptedPromise: null,
    show: false,
    previewData: null,
    file: null,
    product: null,
    pdfScale: 1.3
  }),
  computed: {
    isDeadline () {
      return moment().isAfter(moment('2021-07-01').startOf('day'))
    }
  },
  methods: {
    async open (agreement) {
      this.fetchData(agreement)
      this.accept = true
      this.show = true
      this.acceptedPromise = defer()
      return this.acceptedPromise
    },
    async fetchData (agreement) {
      try {
        const agb = await this.$store.dispatch('agbs/get', agreement.contentId)
        this.product = await this.$store.dispatch('products/get', agreement.productId)
        this.file = await this.$store.dispatch('file/get', agb.fileId)
        this.previewData = this.file.downloadUrl
      } catch (e) {
        console.error(e)
      }
    },
    close () {
      this.acceptedPromise.resolve(null)
      this.show = false
    },
    submit () {
      this.acceptedPromise.resolve(this.accept)
      this.show = false
    }
  }
}
function defer () {
  var res, rej

  var promise = new Promise((resolve, reject) => {
    res = resolve
    rej = reject
  })

  promise.resolve = res
  promise.reject = rej

  return promise
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
  .conditions-box {
    position: relative;
    min-height: 45vh;
    max-height: 45vh;
    background-color: $gray-2;
  }
</style>
