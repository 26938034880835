<template>
  <div class="toolbar">
    <button
      title="Vergrößern"
      class="btn btn-primary p-2 m-1"
      @click="$emit('zoomIn')"
    >
      <i class="far fa-search-plus fa-fw" />
    </button>
    <button
      title="Verkleinern"
      class="btn btn-primary p-2 m-1"
      @click="$emit('zoomOut')"
    >
      <i class="far fa-search-minus fa-fw" />
    </button>
    <SignedDownloadLink
      v-if="file"
      :key="file.key"
      :file="file"
    >
      <template #default="{link}">
        <a
          title="Datei herunterladen"
          :href="link"
          :download="file.name"
          class="btn btn-primary p-2 m-1"
        ><i class="far fa-download fa-fw" /></a>
      </template>
    </SignedDownloadLink>
  </div>
</template>

<script>
import SignedDownloadLink from '@/components/SignedDownloadLink.vue'

export default {
  components: {
    SignedDownloadLink
  },
  props: {
    file: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
  .toolbar {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    top: 15px;
    right: 15px;
    opacity: 0.3;
    transition: .2s;
    &:hover {
      opacity: 1;
    }
  }
</style>
