<template>
  <Modal
    v-if="show"
    size="lg"
    title="Informationspflicht betreffend GKV"
    :closable="false"
    @close="close"
  >
    <template #body>
      <p>
        Informationen
      </p>
      <div
        ref="conditionsBox"
        class="conditions-box"
      >
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.

        Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.

        Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.

        Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.

        Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis.

        At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, At accusam aliquyam diam diam dolore dolores duo eirmod eos erat, et nonumy sed tempor et et invidunt justo labore Stet clita ea et gubergren, kasd magna no rebum. sanctus sea sed takimata ut vero voluptua. est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
      </div>

      <BasicCheckbox
        id="accept"
        type="radio"
        data-test="accept-gkv"
        label="Ja, ich akzeptiere die vorgenannte Änderung"
        :value="accept"
        @input="accept = true"
      />
      <BasicCheckbox
        id="decline"
        type="radio"
        label="Nein, ich widerspreche der vorgenannten Änderung und verlasse die Webseite."
        :value="!accept"
        @input="accept = false"
      />
      <p>
        Sie haben Fragen zu den Änderungen? Sprechen Sie uns einfach an, wir helfen Ihnen gerne weiter.
      </p>
      <p>
        <b>mobile</b> <i>GARANTIE</i> Deutschland GmbH<br>
        Knibbeshof 10 a<br>
        D-30900 Wedemark<br>
        <a href="mailto:info@mobile-garantie.de">info@mobile-garantie.de</a><br>
        Kontakt Deutschland<br>
        +49 (0) 5130 975 70 30<br>
        Kontakt Österreich<br>
        +43 (1) 310 15 23
      </p>
    </template>
    <template #footer>
      <button
        class="btn btn-success text-white float-right"
        :disabled="!conditionsBoxScrolledBottom && accept"
        data-test="gkv-model-submit"
        @click="submit"
      >
        Weiter
      </button>
    </template>
  </modal>
</template>

<script>
import BasicCheckbox from '../BaseComponents/BasicCheckbox'
import Modal from '@/components/Modal.vue'
export default {
  components: {
    Modal,
    BasicCheckbox
  },
  data: () => ({
    accept: true,
    acceptedPromise: null,
    show: false,
    conditionsBoxScrolledBottom: false
  }),
  methods: {
    open () {
      this.accept = true
      this.show = true
      this.acceptedPromise = defer()
      setTimeout(() => this.watchScrollbox())
      return this.acceptedPromise
    },
    close () {
      this.acceptedPromise.resolve(null)
      this.show = false
    },
    submit () {
      this.acceptedPromise.resolve(this.accept)
      this.show = false
    },
    watchScrollbox () {
      const conditionsBox = this.$refs.conditionsBox
      const onscroll = () => {
        const reachedBottom = conditionsBox.scrollHeight - conditionsBox.scrollTop === conditionsBox.clientHeight
        if (reachedBottom) {
          this.conditionsBoxScrolledBottom = reachedBottom
          conditionsBox.removeEventListener('scroll', onscroll)
        }
      }
      conditionsBox.addEventListener('scroll', onscroll)
    }
  }
}
function defer () {
  var res, rej

  var promise = new Promise((resolve, reject) => {
    res = resolve
    rej = reject
  })

  promise.resolve = res
  promise.reject = rej

  return promise
}
</script>

<style lang="scss">
  .conditions-box {
    overflow-y: scroll;
    height: 20vh;
  }
</style>
